import React, { useEffect } from "react"
import AOS from 'aos';
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


const BemVindo = () => {

  useEffect(() => {
    AOS.init()
    }, []);


  return (
    <main>
        <Seo
        title="Bem-vindo ao wEstoque 📦"
        />

        <Header />

        <section style={{paddingTop: `100px`, marginBottom: `250px`}}>

            <div className="max-w-6xl mx-auto px-6 sm:px-4 overflow-x-hidden">
                <div className="py-8 md:py-15">        



                    <div className="grid grid-cols-1 aos-init aos-animate mt-0 text-center" data-aos="fade-left">

                        

                        <StaticImage
                            src="../images/icone-cadastro-ok.png"
                            alt="Sistema wEstoque - PDV online, financeiro e fiscal"
                            placeholder="blurred"
                            layout="fixed"
                            quality={100}
                            
                            className="max-w-full mx-auto md:max-w-none h-auto"
                        />
                        <h1 className="text-center text-5xl font-bold"><br/>Seu cadastro foi realizado com sucesso!</h1>
                        <br/>
                        <p className="font-semibold text-xl text-purple-600 mb-2 max-w-lg h-auto mx-auto">A PARTIR DE AGORA VOCÊ JÁ PODE USAR O SEU WESTOQUE PARA GANHAR TEMPO E FOCAR NO SEU NEGÓCIO :)</p>
                        <br/>
                        <a href="https://sistema.westoque.com.br" className="mx-auto h-auto max-w-sm font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out">ACESSAR O SISTEMA</a>

                    </div>




                </div>
            </div>
        </section>


        <Footer />

    </main>
  )
}

export default BemVindo
